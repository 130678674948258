import _baseToString from "./_baseToString";
import _baseTrim from "./_baseTrim";
import _castSlice from "./_castSlice";
import _charsEndIndex from "./_charsEndIndex";
import _charsStartIndex from "./_charsStartIndex";
import _stringToArray from "./_stringToArray";
import _toString from "./toString";
var exports = {};
var baseToString = _baseToString,
  baseTrim = _baseTrim,
  castSlice = _castSlice,
  charsEndIndex = _charsEndIndex,
  charsStartIndex = _charsStartIndex,
  stringToArray = _stringToArray,
  toString = _toString;

/**
 * Removes leading and trailing whitespace or specified characters from `string`.
 *
 * @static
 * @memberOf _
 * @since 3.0.0
 * @category String
 * @param {string} [string=''] The string to trim.
 * @param {string} [chars=whitespace] The characters to trim.
 * @param- {Object} [guard] Enables use as an iteratee for methods like `_.map`.
 * @returns {string} Returns the trimmed string.
 * @example
 *
 * _.trim('  abc  ');
 * // => 'abc'
 *
 * _.trim('-_-abc-_-', '_-');
 * // => 'abc'
 *
 * _.map(['  foo  ', '  bar  '], _.trim);
 * // => ['foo', 'bar']
 */
function trim(string, chars, guard) {
  string = toString(string);
  if (string && (guard || chars === undefined)) {
    return baseTrim(string);
  }
  if (!string || !(chars = baseToString(chars))) {
    return string;
  }
  var strSymbols = stringToArray(string),
    chrSymbols = stringToArray(chars),
    start = charsStartIndex(strSymbols, chrSymbols),
    end = charsEndIndex(strSymbols, chrSymbols) + 1;
  return castSlice(strSymbols, start, end).join("");
}
exports = trim;
export default exports;